'use client';
import { UploadedFiles } from '@/components/internalComponents/form/uploadedFiles';
import { FormSuccesOverlay } from '@/components/internalComponents/formSuccesOverlay';
import { zodResolver } from '@hookform/resolvers/zod';
import { AnimatePresence } from 'framer-motion';
import {
  formatHubspotFormBody,
  submitHubspotForm,
  uploadHubspotFile,
} from 'helpers/hubspotApiHelpers';
import { scrollTo } from 'helpers/scrollTo';
import { ReactElement, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '../commonComponents/button/button';
import { TextField } from '../internalComponents/form/textField';
import { UploadField } from '../internalComponents/form/uploadfield';
import styles from './styles.module.scss';

const NEW_BUSINESS_FORM = 'new-business-form' as const;

const ContactFormSchema = z.object({
  firstname: z.string().nonempty(),
  lastname: z.string().nonempty(),
  company: z.string().optional(),
  email: z.string().email(),
  phone: z.string().optional(),
  questions: z.string().nonempty(),
  briefing: z.any().optional(),
});

type ContactFormSchemaType = z.infer<typeof ContactFormSchema>;

export const NewBusinessForm = (): ReactElement<HTMLFormElement> => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<ContactFormSchemaType>({
    resolver: zodResolver(ContactFormSchema),
  });

  const [briefing, setBriefing] = useState<File | null>(null);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleCloseSuccessOverlay = () => {
    reset({
      firstname: '',
      lastname: '',
      company: '',
      email: '',
      phone: '',
      questions: '',
    });
    setBriefing(null);
    setShowSuccess(false);
  };

  const onSubmit: SubmitHandler<ContactFormSchemaType> = async (data) => {
    const uploadFile = briefing
      ? await uploadHubspotFile({
          file: briefing,
          fileName: briefing.name,
          folderPath: 'briefings',
          options: {
            access: 'PUBLIC_NOT_INDEXABLE',
          },
        }).catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
          return null;
        })
      : null;

    const response = await submitHubspotForm({
      portalID: '7922064',
      formID: 'b00af6dd-a5a0-4b2a-bd0e-c69738586edb',
      body: {
        submittedAt: new Date().toISOString(),
        fields: formatHubspotFormBody({
          ...data,
          briefing: uploadFile?.objects[0].url ?? '',
        }),
      },
    });

    if (response.inlineMessage) {
      setShowSuccess(true);
      scrollTo(NEW_BUSINESS_FORM, -200);
    }
  };

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(onSubmit)}
      id={NEW_BUSINESS_FORM}
    >
      <div className={styles.name}>
        <Controller
          name="firstname"
          control={control}
          render={({ field }) => (
            <TextField
              label="Voornaam"
              type="text"
              required
              error={errors.firstname?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="lastname"
          control={control}
          render={({ field }) => (
            <TextField
              label="Achternaam"
              type="text"
              required
              error={errors.lastname?.message}
              {...field}
            />
          )}
        />
      </div>

      <Controller
        name="company"
        control={control}
        render={({ field }) => (
          <>
            <TextField
              label="Bedrijfsnaam"
              type="text"
              error={errors.company?.message}
              {...field}
            />
          </>
        )}
      />

      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <>
            <TextField
              label="Email"
              type="email"
              required
              error={errors.email?.message}
              {...field}
            />
          </>
        )}
      />
      <Controller
        name="phone"
        control={control}
        render={({ field }) => (
          <TextField
            label="Telefoonnummer"
            type="tel"
            error={errors?.phone?.message}
            {...field}
          />
        )}
      />

      <Controller
        name="questions"
        control={control}
        render={({ field }) => (
          <TextField
            label="Vraag of opmerking?"
            type="text"
            {...field}
            required
            error={errors.questions?.message}
            multiline
            rows={10}
          />
        )}
      />

      <UploadField
        label="Heb je al een briefing als PDF of ander document? Top! Deze kun je hier uploaden. Alle informatie wordt vertrouwelijk behandeld."
        name="briefing"
        value={briefing}
        onChange={setBriefing}
      />
      {briefing && (
        <UploadedFiles
          files={[
            {
              fileName: briefing.name,
              fileSize: briefing.size,
              removeFile: () => setBriefing(null),
            },
          ]}
        />
      )}

      <Button
        style="primary"
        type="submit"
        className={styles.submit}
        disabled={isSubmitting}
        icon="ArrowRight"
      >
        {isSubmitting ? 'Versturen . . .' : 'Verstuur'}
      </Button>
      <AnimatePresence>
        {showSuccess && (
          <FormSuccesOverlay
            title="Message received!"
            message="Bedankt voor je berichtje! We proberen uiterlijk binnen 1 werkdag te reageren."
            onClose={handleCloseSuccessOverlay}
          />
        )}
      </AnimatePresence>
    </form>
  );
};
