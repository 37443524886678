import { env } from 'helpers/env';
import {
  HubspotFileUploadBody,
  HubspotFileUploadResponse,
  HubspotFormResponse,
  HubspotFormSubmission,
} from 'types/hubspot';

export const uploadHubspotFile = async (
  data: HubspotFileUploadBody
): Promise<HubspotFileUploadResponse> => {
  const formData = new FormData();

  formData.append('file', data.file);
  formData.append('fileName', data.fileName ?? data.file.name);
  formData.append('folderPath', data.folderPath);
  formData.append('options', JSON.stringify(data.options));

  const response: Response = await fetch(`/api/hubspot-file-upload`, {
    method: 'POST',
    body: formData,
  });

  return response.json();
};

export const submitHubspotForm = async (
  data: HubspotFormSubmission
): Promise<HubspotFormResponse> => {
  if (!env.NEXT_PUBLIC_HUBSPOT_FORM_API_ENDPOINT) {
    throw new Error(
      'NEXT_PUBLIC_HUBSPOT_FORM_API_ENDPOINT missing from env variables'
    );
  }
  const response: Response = await fetch(
    `${env.NEXT_PUBLIC_HUBSPOT_FORM_API_ENDPOINT}/${data.portalID}/${data.formID}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data.body),
    }
  );
  return response.json();
};

export const formatHubspotFormBody = (data: Record<string, string>) => {
  return Object.entries(data).map(([key, value]) => ({
    objectTypeId: '0-1',
    name: key,
    value: value ?? '',
  }));
};
