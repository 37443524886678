import { FormSuccesOverlay } from '@/components/internalComponents/formSuccesOverlay';
import { zodResolver } from '@hookform/resolvers/zod';
import { AnimatePresence } from 'framer-motion';
import {
  formatHubspotFormBody,
  submitHubspotForm,
} from 'helpers/hubspotApiHelpers';
import { scrollTo } from 'helpers/scrollTo';
import { ReactElement, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '../commonComponents/button/button';
import { TextField } from '../internalComponents/form/textField';
import styles from './styles.module.scss';

const JUST_SAY_HI_FORM = 'just-say-hi-form' as const;

const ContactFormSchema = z.object({
  firstname: z.string().nonempty(),
  lastname: z.string().nonempty(),
  company: z.string().optional(),
  email: z.string().email(),
  phone: z.string().optional(),
  message: z.string().nonempty(),
});

type ContactFormSchemaType = z.infer<typeof ContactFormSchema>;

export const JustSayHiForm = (): ReactElement<HTMLFormElement> => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<ContactFormSchemaType>({
    resolver: zodResolver(ContactFormSchema),
  });

  const [showSuccess, setShowSuccess] = useState(false);

  const handleCloseSuccessOverlay = () => {
    reset({
      firstname: '',
      lastname: '',
      company: '',
      email: '',
      phone: '',
      message: '',
    });
    setShowSuccess(false);
  };

  const onSubmit: SubmitHandler<ContactFormSchemaType> = async (data) => {
    const response = await submitHubspotForm({
      portalID: '7922064',
      formID: '73dad0d8-35ad-4845-8ea0-eede60fa9191',
      body: {
        submittedAt: new Date().toISOString(),
        fields: formatHubspotFormBody({
          ...data,
        }),
      },
    });

    if (response.inlineMessage) {
      setShowSuccess(true);
      scrollTo(JUST_SAY_HI_FORM, -200);
    }
  };

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(onSubmit)}
      id={JUST_SAY_HI_FORM}
    >
      <div className={styles.name}>
        <Controller
          name="firstname"
          control={control}
          render={({ field }) => (
            <TextField
              label="Voornaam"
              type="text"
              required
              error={errors.firstname?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="lastname"
          control={control}
          render={({ field }) => (
            <TextField
              label="Achternaam"
              type="text"
              required
              error={errors.lastname?.message}
              {...field}
            />
          )}
        />
      </div>

      <Controller
        name="company"
        control={control}
        render={({ field }) => (
          <>
            <TextField
              label="Bedrijfsnaam"
              type="text"
              error={errors.company?.message}
              {...field}
            />
          </>
        )}
      />

      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <>
            <TextField
              label="Email"
              type="email"
              required
              error={errors.email?.message}
              {...field}
            />
          </>
        )}
      />
      <Controller
        name="phone"
        control={control}
        render={({ field }) => (
          <TextField
            label="Telefoonnummer"
            type="tel"
            error={errors.phone?.message}
            {...field}
          />
        )}
      />

      <Controller
        name="message"
        control={control}
        render={({ field }) => (
          <TextField
            label="Vraag of opmerking?"
            type="text"
            {...field}
            required
            error={errors.message?.message}
            multiline
            rows={10}
          />
        )}
      />

      <Button
        style="primary"
        type="submit"
        className={styles.submit}
        disabled={isSubmitting}
        icon="ArrowRight"
      >
        {isSubmitting ? 'Versturen . . .' : 'Verstuur'}
      </Button>
      <AnimatePresence>
        {showSuccess && (
          <FormSuccesOverlay
            title="Message received!"
            message="Bedankt voor je berichtje! We proberen uiterlijk binnen 1 werkdag te reageren."
            onClose={handleCloseSuccessOverlay}
          />
        )}
      </AnimatePresence>
    </form>
  );
};
