'use client';
import { useState } from 'react';

import { FormSelector } from 'components/form-selector';
import { JustSayHiForm } from 'components/just-say-hi-form';
import { LayoutContainer } from 'components/layout-container';
import { LinkButton } from 'components/link-button';
import { NewBusinessForm } from 'components/new-business-form';
import { Typography } from 'components/typography';
import { VacancyForm } from 'components/vacancy-form';

import styles from './styles.module.scss';

export type getInTouchProps = {
  drawer?: boolean;
  onClick?: () => void;
};

export const GetInTouch = ({ drawer = false, onClick }: getInTouchProps) => {
  const [activeForm, setActiveForm] = useState<number | null>(null);

  return (
    <LayoutContainer className={styles.getInTouchContainer} size="medium">
      {!drawer && <GetInTouchColumnIntro />}
      <FormSelector
        className={drawer ? styles.formSelectorDrawer : styles.formSelector}
        options={[
          {
            name: 'New Business',
            form: <NewBusinessForm />,
            subtitle: (
              <Typography variant="paragraph-reg" tag="p" color="muted">
                Opzoek naar een consultant om je project naar een hoger niveau
                te tillen? Let us know!
              </Typography>
            ),
          },
          {
            name: 'Werken bij [code]capi',
            form: <VacancyForm vacancyId={1} />,
            subtitle: (
              <Typography variant="paragraph-reg" tag="p" color="muted">
                Open sollicitatie of heb je een vraag over een vacature? Vul
                onderstaand formulier in. We leren je graag kennen! Voor
                vacatures kan je terecht op onze{' '}
                <LinkButton
                  className={styles.linkButton}
                  label="vacaturepagina."
                  href="/vacatures"
                  variant="compact"
                  onClick={onClick}
                />
              </Typography>
            ),
          },
          {
            name: 'Just say hi!',
            form: <JustSayHiForm />,
          },
        ]}
        animateHeight={!drawer}
        introComponent={
          drawer ? <GetInTouchDrawerIntro onClick={onClick} /> : undefined
        }
        activeForm={activeForm}
        setActiveForm={setActiveForm}
      />
    </LayoutContainer>
  );
};

const GetInTouchDrawerIntro = ({ onClick }: { onClick?: () => void }) => (
  <div className={styles.textBlock}>
    <Typography variant="paragraph-reg" tag="p">
      Laat een berichtje achter via een van onderstaande formulieren, of{' '}
      <LinkButton
        className={styles.contactButton}
        label="neem direct contact op."
        href="/contact"
        variant="compact"
        onClick={onClick}
      ></LinkButton>
    </Typography>
  </div>
);

const GetInTouchColumnIntro = () => (
  <div className={styles.textBlock}>
    <Typography variant="heading2-compact" tag="h3">
      Get in touch
    </Typography>
    <Typography variant="paragraph-reg" tag="p">
      Wil je dat wij contact met jou opnemen? Vul een van onze formulieren in en
      binnen 1 werkdag bellen we je terug.
    </Typography>
  </div>
);
