import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactElement, useEffect, useRef, useState } from 'react';

import { ListItem } from 'components/list-item';
import { Typography } from 'components/typography';

import { IconButton } from '../commonComponents/iconButton/iconButton';
import { Icon } from '../internalComponents/icon';
import styles from './styles.module.scss';

type FormSelectorOption = {
  name: string;
  form: ReactElement<HTMLFormElement>;
  subtitle?: ReactElement;
};

type FormSelectorProps = {
  options: FormSelectorOption[];
  className?: string;
  activeForm: number | null;
  setActiveForm: (index: number | null) => void;
  introComponent?: ReactElement;
  animateHeight?: boolean;
};

export const FormSelector = ({
  options,
  className,
  activeForm,
  setActiveForm,
  introComponent,
  animateHeight = false,
}: FormSelectorProps) => {
  const [minHeightSelector, setMinHeightSelector] = useState<string>('auto');

  const formSelectorRef = useRef<HTMLDivElement>(null);

  const handleFormSelectorHeight = () => {
    if (formSelectorRef.current) {
      setMinHeightSelector(`${formSelectorRef.current.clientHeight}px`);
    }
  };

  useEffect(() => {
    handleFormSelectorHeight();
    window.addEventListener('resize', handleFormSelectorHeight);
    return () => {
      window.removeEventListener('resize', handleFormSelectorHeight);
    };
  }, []);

  const enterTransition = {
    height: { duration: 0.32 },
    x: {
      duration: 0.15,
      delay: 0.32,
    },
    opacity: {
      duration: 0.15,
      delay: 0.32,
    },
  };

  const exitTransition = {
    height: {
      duration: 0.15,
    },
    opacity: {
      duration: 0.15,
    },
  };

  return (
    <div
      className={classNames(styles.formSelectorWrapper, className)}
      ref={formSelectorRef}
    >
      <AnimatePresence initial={false}>
        {activeForm === null && (
          <motion.div
            className={styles.formSelectorInnerWrapper}
            initial={{
              height: animateHeight ? minHeightSelector : 'auto',
              opacity: 0,
              x: -20,
            }}
            animate={{
              height: 'auto',
              opacity: 1,
              x: 0,
              transition: enterTransition,
            }}
            exit={{
              height: animateHeight ? 0 : 'auto',
              opacity: 0,
              x: -20,
              transition: exitTransition,
            }}
          >
            {introComponent}
            <ul className={styles.formSelectorList}>
              {options.map((option, index) => (
                <ListItem
                  key={index}
                  heading={option.name}
                  border
                  onClick={() => setActiveForm(index)}
                  className={styles.formSelectorItem}
                  trailingAddon={
                    <span className={styles.formSelectorItemAddon}>
                      <Icon name="LongArrow" className={styles.growingArrow} />
                    </span>
                  }
                />
              ))}
            </ul>
          </motion.div>
        )}
        {options.map(
          (option, index) =>
            activeForm === index && (
              <motion.div
                key={index}
                initial={{
                  height: animateHeight ? minHeightSelector : 'auto',
                  opacity: 0,
                  x: 20,
                }}
                animate={{
                  height: 'auto',
                  opacity: 1,
                  x: 0,
                  transition: enterTransition,
                }}
                exit={{
                  height: animateHeight ? 0 : 'auto',
                  opacity: 0,
                  x: 20,
                  transition: exitTransition,
                }}
              >
                <div className={styles.formHeader}>
                  <IconButton
                    icon="ArrowLeft"
                    onClick={() => setActiveForm(null)}
                    style="muted"
                    shape="circle"
                    withoutPadding
                  />
                  <Typography variant="label-reg" tag="span">
                    {option.name}
                  </Typography>
                </div>
                {option.subtitle && (
                  <div className={styles.subtitleWrapper}>
                    <Typography variant="paragraph-reg" tag="p" color="muted">
                      {option.subtitle}
                    </Typography>
                  </div>
                )}
                {option.form}
              </motion.div>
            )
        )}
      </AnimatePresence>
    </div>
  );
};
